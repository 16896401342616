import { useLocale, useTranslations } from 'next-intl';
import { Link } from 'react-transition-progress/next';
import { PATHS } from '@/constants/path';

import { usePathname } from '@/i18n/routing';

type NavItem = {
  title: string;
  path: string;
  external?: boolean;
};

// TODO: pathを確認する

export const Support = () => {
  const t = useTranslations();
  const locale = useLocale();

  const NAV_ITEMS: NavItem[] = [
    {
      title:
        locale === 'ja'
          ? `KLEW ${t('settings-menu-about_jp')}`
          : `${t('settings-menu-about_en')} KLEW`,
      path: PATHS.ABOUT,
    },
    {
      title: t('settings-menu-about_for_artists'),
      path: PATHS.ABOUT_FOR_ARTISTS,
      external: true,
    },
    {
      title: t('settings-menu-contact'),
      path: PATHS.CONTACT,
      external: true,
    },
    {
      title: t('settings-menu-faq'),
      path: PATHS.FAQ,
      external: true,
    },
    {
      title: t('settings-menu-terms'),
      path: PATHS.TERMS,
      external: true,
    },
    {
      title: t('settings-menu-privacy'),
      path: PATHS.PRIVACY_POLICY,
      external: true,
    },
    {
      title: t('settings-menu-law'),
      path: PATHS.LEGAL,
      external: true,
    },
  ];
  const pathname = usePathname();

  const shouldActive = (item: NavItem) => {
    const isCurrentPathInNavItems = pathname === item.path;
    const isCurrentPathMatchingNavItem = NAV_ITEMS.some((item) => pathname === item.path);

    return isCurrentPathInNavItems || !isCurrentPathMatchingNavItem;
  };

  return (
    <div className='ml-1 flex flex-col py-4 text-white'>
      {NAV_ITEMS.map((item) => (
        <Link
          key={item.title}
          href={item.path}
          className='py-2 text-sm first:pt-0 last:pb-0'
          target={item.external ? '_blank' : '_self'}
        >
          <div className={`text-[1rem] font-bold ${shouldActive(item)}`}>{item.title}</div>
        </Link>
      ))}
    </div>
  );
};
