import type {
  ErrorAlreadyExists,
  ErrorCannotCancelTicketLotteryApplication,
  ErrorCannotMarkTicketSummaryAsRead,
  ErrorCreditCardRegistrationLimitExceeded,
  ErrorDifferentArtistChannel,
  ErrorEmailVerificationAlreadyInUse,
  ErrorEmailVerificationAlreadyVerified,
  ErrorExceedsApplyLimit,
  ErrorExternalTicketRegistrationScheduleHasEnded,
  ErrorFailedToTempReserveTicketSale,
  ErrorFanStatusInvalid,
  ErrorHasNotVerifiedEmail,
  ErrorIncludesNonRefundableMyTicket,
  ErrorInternal,
  ErrorNonCancelableTicketApplication,
  ErrorNotFound,
  ErrorNotMyArtistContent,
  ErrorNotMyChannelContent,
  ErrorNotMyFanContent,
  ErrorNotTransferingTicket,
  ErrorPermissionDenied,
  ErrorPreconditionFailed,
  ErrorRecaptchaScoreError,
  ErrorValidationFailed,
  PurchaseTicketErrorAmountLimitExceeded,
  PurchaseTicketErrorCardDeclined,
  PurchaseTicketErrorFanHasAlreadyWithdrawn,
  PurchaseTicketErrorInsufficientBalance,
  PurchaseTicketErrorTicketApplicationHasBeenOrdered,
  PurchaseTicketErrorTicketApplicationHasExpired,
  VerifyEmailPayloadErrorInvalidToken,
  ErrorAlreadyHasReviewingOrActiveTicketApplication,
  PurchaseSuperMessageErrorPaymentProcessingFailure,
} from '@/__generated__/graphql';

export type ErrorTypes =
  | ErrorAlreadyExists
  | ErrorCannotCancelTicketLotteryApplication
  | ErrorCannotMarkTicketSummaryAsRead
  | ErrorCreditCardRegistrationLimitExceeded
  | ErrorDifferentArtistChannel
  | ErrorEmailVerificationAlreadyInUse
  | ErrorEmailVerificationAlreadyVerified
  | ErrorExceedsApplyLimit
  | ErrorExternalTicketRegistrationScheduleHasEnded
  | ErrorFailedToTempReserveTicketSale
  | ErrorFanStatusInvalid
  | ErrorHasNotVerifiedEmail
  | ErrorIncludesNonRefundableMyTicket
  | ErrorInternal
  | ErrorNonCancelableTicketApplication
  | ErrorNotFound
  | ErrorNotMyArtistContent
  | ErrorNotMyChannelContent
  | ErrorNotMyFanContent
  | ErrorNotTransferingTicket
  | ErrorPermissionDenied
  | ErrorPreconditionFailed
  | ErrorRecaptchaScoreError
  | ErrorValidationFailed
  | PurchaseTicketErrorAmountLimitExceeded
  | PurchaseTicketErrorCardDeclined
  | PurchaseTicketErrorFanHasAlreadyWithdrawn
  | PurchaseTicketErrorInsufficientBalance
  | PurchaseTicketErrorTicketApplicationHasBeenOrdered
  | PurchaseTicketErrorTicketApplicationHasExpired
  | VerifyEmailPayloadErrorInvalidToken
  | ErrorAlreadyHasReviewingOrActiveTicketApplication
  | PurchaseSuperMessageErrorPaymentProcessingFailure;

type SuccessType = { __typename: string; ok: boolean };

export function isErrorType(obj: Record<string, unknown>): obj is ErrorTypes {
  return typeof obj?.__typename === 'string' && obj.__typename.includes('Error');
}

export function isSuccessType(obj: Record<string, unknown>): obj is SuccessType {
  return typeof obj?.__typename === 'string' && obj.__typename.includes('Success') && 'ok' in obj;
}

/**
 * ハンドリング すべき`__typename`を探索し、見つかった場合そのオブジェクトを返す
 *
 * @param {unknown} obj - 検索対象のオブジェクト。
 * @returns {ErrorTypes | SuccessType | null} 見つかった `ErrorTypes`  | `SuccessType` のオブジェクト。見つからなかった場合は null。
 */
export function findErrorOrSuccessType(obj: unknown): ErrorTypes | SuccessType | null {
  if (obj === null || obj === undefined) {
    return null;
  }

  if (typeof obj === 'object') {
    const recordObj = obj as Record<string, unknown>;

    if (isErrorType(recordObj)) {
      return recordObj;
    }

    if (isSuccessType(recordObj)) {
      return recordObj;
    }

    for (const key in recordObj) {
      if (Object.prototype.hasOwnProperty.call(recordObj, key)) {
        const foundObj = findErrorOrSuccessType(recordObj[key]);
        if (foundObj) {
          return foundObj;
        }
      }
    }
  }

  return null;
}
