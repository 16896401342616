import type { useTranslations } from 'next-intl';
import type { ErrorTypes } from '@/utils/find-error-or-success-type';

export const PAYLOAD_ERROR_MAP = (
  errorCode: ErrorTypes | { __typename: 'ErrorValidationNgWord' },
  t: ReturnType<typeof useTranslations>,
) => {
  switch (errorCode.__typename) {
    // 異なるアーティストのチャンネルエラー
    case 'ErrorDifferentArtistChannel':
      return {
        title: t('payload_error-ErrorDifferentArtistChannel-title'),
        body: [t('payload_error-ErrorDifferentArtistChannel-body')],
      };
    // 自アーティスト投稿ではないエラー
    case 'ErrorNotMyArtistContent':
      return {
        title: t('payload_error-ErrorNotMyArtistContent-title'),
        body: [t('payload_error-ErrorNotMyArtistContent-body')],
      };
    // 自身が管理するチャンネルコンテンツではないエラー
    case 'ErrorNotMyChannelContent':
      return {
        title: t('payload_error-ErrorNotMyChannelContent-title'),
        body: [t('payload_error-ErrorNotMyChannelContent-body')],
      };
    // 自身が投稿したファン投稿ではないエラー
    case 'ErrorNotMyFanContent':
      return {
        title: t('payload_error-ErrorNotMyFanContent-title'),
        body: [t('payload_error-ErrorNotMyFanContent-body')],
      };
    // 別のアカウントで登録済みのEmailアドレスで変更または認証した場合のエラー
    case 'ErrorEmailVerificationAlreadyInUse':
      return {
        title: t('payload_error-ErrorEmailVerificationAlreadyInUse-title'),
        body: [t('payload_error-ErrorEmailVerificationAlreadyInUse-body')],
      };
    // 認証済みEmailアドレスで変更または認証した場合のエラー
    case 'ErrorEmailVerificationAlreadyVerified':
      return {
        title: t('payload_error-ErrorEmailVerificationAlreadyVerified-title'),
        body: [t('payload_error-ErrorEmailVerificationAlreadyVerified-body')],
      };
    // ファンのステータスが不正な場合のエラー
    case 'ErrorFanStatusInvalid':
      return {
        title: t('payload_error-ErrorFanStatusInvalid-title'),
        body: [t('payload_error-ErrorFanStatusInvalid-body')],
      };
    // クレジットカード登録枚数上限超過エラー
    case 'ErrorCreditCardRegistrationLimitExceeded':
      return {
        title: t('payload_error-ErrorCreditCardRegistrationLimitExceeded-title'),
        body: [t('payload_error-ErrorCreditCardRegistrationLimitExceeded-body')],
      };
    // Eメールアドレス未認証エラー
    case 'ErrorHasNotVerifiedEmail':
      return {
        title: t('payload_error-ErrorHasNotVerifiedEmail-title'),
        body: [t('payload_error-ErrorHasNotVerifiedEmail-body')],
      };
    // 権限無しエラー
    case 'ErrorPermissionDenied':
      return {
        title: t('payload_error-ErrorPermissionDenied-title'),
        body: [t('payload_error-ErrorPermissionDenied-body')],
      };
    // 入力値チェックエラー
    case 'ErrorValidationFailed':
      return {
        title: t('payload_error-ErrorValidationFailed-title'),
        body: [t('payload_error-ErrorValidationFailed-body')],
      };
    // NGワードだった場合のエラー
    case 'ErrorValidationNgWord':
      return {
        title: [t('common-error-title')],
        body: [t('common-error-ng_word')],
      };
    case 'ErrorNotFound':
      return {
        title: t('payload_error-ErrorNotFound-title'),
        body: [t('payload_error-ErrorNotFound-body')],
      };
    // 入力値のエンティティが既に存在するエラー
    case 'ErrorAlreadyExists':
      return {
        title: t('payload_error-ErrorAlreadyExists-title'),
        body: [t('payload_error-ErrorAlreadyExists-body')],
      };
    // reCAPTCHA評価の結果、不正の可能性があるエラー
    case 'ErrorRecaptchaScoreError':
      return {
        title: 'reCAPTCHAスコアエラー',
        body: ['不正の可能性があるため、操作を中止しました。再試行してください。'],
      };
    // データ挿入/更新の条件を満たしていないエラー
    case 'ErrorPreconditionFailed':
      return {
        title: t('payload_error-ErrorPreconditionFailed-title'),
        body: [t('payload_error-ErrorPreconditionFailed-body')],
      };
    // システム内部エラー
    case 'ErrorInternal':
      return {
        title: t('payload_error-ErrorInternal-title'),
        body: [t('payload_error-ErrorInternal-body')],
      };
    // 移転中のチケットではないエラー
    case 'ErrorNotTransferingTicket':
      return {
        title: t('payload_error-ErrorNotTransferingTicket-title'),
        body: [t('payload_error-ErrorNotTransferingTicket-body')],
      };
    // 外部チケット登録の受付終了エラー
    case 'ErrorExternalTicketRegistrationScheduleHasEnded':
      return {
        title: t('payload_error-ErrorExternalTicketRegistrationScheduleHasEnded-title'),
        body: [t('payload_error-ErrorExternalTicketRegistrationScheduleHasEnded-body')],
      };
    // キャンセルできない抽選申込ステータスエラー
    case 'ErrorCannotCancelTicketLotteryApplication':
      return {
        title: t('payload_error-ErrorCannotCancelTicketLotteryApplication-title'),
        body: [t('payload_error-ErrorCannotCancelTicketLotteryApplication-body')],
      };
    // 返金リクエストできないマイチケットが含まれているエラー
    case 'ErrorIncludesNonRefundableMyTicket':
      return {
        title: t('payload_error-ErrorIncludesNonRefundableMyTicket-title'),
        body: [t('payload_error-ErrorIncludesNonRefundableMyTicket-body')],
      };
    // 購入後参照フラグをTrue=既読とできないチケットサマリエラー
    case 'ErrorCannotMarkTicketSummaryAsRead':
      return {
        title: t('payload_error-ErrorCannotMarkTicketSummaryAsRead-title'),
        body: [t('payload_error-ErrorCannotMarkTicketSummaryAsRead-body')],
      };
    // キャンセルできないチケット申込エラー
    case 'ErrorNonCancelableTicketApplication':
      return {
        title: t('payload_error-ErrorNonCancelableTicketApplication-title'),
        body: [t('payload_error-ErrorNonCancelableTicketApplication-body')],
      };
    // 枚数制限超過エラー
    case 'ErrorExceedsApplyLimit':
      return {
        title: t('payload_error-ErrorExceedsApplyLimit-title'),
        body: [t('payload_error-ErrorExceedsApplyLimit-body')],
      };
    // チケット仮押さえ失敗エラー
    case 'ErrorFailedToTempReserveTicketSale':
      return {
        title: t('payload_error-ErrorFailedToTempReserveTicketSale-title'),
        body: [t('payload_error-ErrorFailedToTempReserveTicketSale-body')],
      };
    // 退会済みのファンによるチケット購入エラー
    case 'PurchaseTicketErrorFanHasAlreadyWithdrawn':
      return {
        title: t('payload_error-PurchaseTicketErrorFanHasAlreadyWithdrawn-title'),
        body: [t('payload_error-PurchaseTicketErrorFanHasAlreadyWithdrawn-body')],
      };
    // Emailアドレス認証（設定・変更兼用）ペイロード（トークン不正）
    case 'VerifyEmailPayloadErrorInvalidToken':
      return {
        title: t('payload_error-VerifyEmailPayloadErrorInvalidToken-title'),
        body: [t('payload_error-VerifyEmailPayloadErrorInvalidToken-body')],
      };
    // チケット申込の支払い期限を過ぎているエラー
    case 'PurchaseTicketErrorTicketApplicationHasExpired':
      return {
        title: t('payload_error-PurchaseTicketErrorTicketApplicationHasExpired-title'),
        body: [t('payload_error-PurchaseTicketErrorTicketApplicationHasExpired-body')],
      };
    // 購入済みまたは購入処理中のチケット申込に対する購入エラー
    case 'PurchaseTicketErrorTicketApplicationHasBeenOrdered':
      return {
        title: t('payload_error-PurchaseTicketErrorTicketApplicationHasBeenOrdered-title'),
        body: [t('payload_error-PurchaseTicketErrorTicketApplicationHasBeenOrdered-body')],
      };
    // カード残高不足によるチケット購入エラー
    case 'PurchaseTicketErrorInsufficientBalance':
      return {
        title: t('payload_error-PurchaseTicketErrorInsufficientBalance-title'),
        body: [t('payload_error-PurchaseTicketErrorInsufficientBalance-body')],
      };
    // カード限度額の超過によるチケット購入エラー
    case 'PurchaseTicketErrorAmountLimitExceeded':
      return {
        title: t('payload_error-PurchaseTicketErrorAmountLimitExceeded-title'),
        body: [t('payload_error-PurchaseTicketErrorAmountLimitExceeded-body')],
      };
    // カード起因のエラーによるチケット購入エラー
    case 'PurchaseTicketErrorCardDeclined':
      return {
        title: t('payload_error-PurchaseTicketErrorCardDeclined-title'),
        body: [t('payload_error-PurchaseTicketErrorCardDeclined-body')],
      };
    //  既にレビュー中 または 有効なチケット申し込みを持っているエラー
    case 'ErrorAlreadyHasReviewingOrActiveTicketApplication':
      return {
        title: t('ErrorAlreadyHasReviewingOrActiveTicketApplication-title'),
        body: [t('ErrorAlreadyHasReviewingOrActiveTicketApplication-body')],
      };
    case 'PurchaseSuperMessageErrorPaymentProcessingFailure':
      return {
        title: t('PurchaseSuperMessageErrorPaymentProcessingFailure-title'),
        body: [t('PurchaseSuperMessageErrorPaymentProcessingFailure-body')],
      };
    default: {
      // もし新しいエラーコードが追加され、それがswitch文でcase網羅されていない場合、
      // defaultケースでのnever型の代入は型エラーを引き起こすようにする(※ex...型 'string' を型 'never' に割り当てることはできません。)
      // コンパイル時（Build時）にすべてのcaseが網羅されているかどうかをチェックする
      // しかしBEとFEで同期し続ける難しいのでランタイム環境（ブラウザ）のことを考慮しデファルトのメッセージを返す
      const _exhaustiveCheck: never = errorCode;
      return {
        title: t('error-INTERNAL-title'),
        body: [t('error-INTERNAL-body')],
      };
    }
  }
};
