'use client';

import { ApolloWrapper } from '@/lib/apollo/client';
import { DialogProvider } from '@/providers/dialog-provider';

export const WithApolloProvider = ({
  children,
  cookieHeader,
}: React.PropsWithChildren & { cookieHeader: string }) => {
  return (
    <DialogProvider>
      {(showDialog) => {
        return (
          <ApolloWrapper showDialog={showDialog} cookieHeader={cookieHeader}>
            {children}
          </ApolloWrapper>
        );
      }}
    </DialogProvider>
  );
};
