import type { useTranslations } from 'next-intl';

type ERROR_CODE = 'INTERNAL' | 'OPEN_SEA_INTERNAL' | 'NOT_FOUND' | 'UNAUTHORIZED' | 'NETWORK';

export const ERROR_MAP = (errorCode: ERROR_CODE, t: ReturnType<typeof useTranslations>) => {
  switch (errorCode) {
    case 'INTERNAL':
      return {
        title: t('error-INTERNAL-title'),
        body: [t('error-INTERNAL-body')],
      };
    case 'OPEN_SEA_INTERNAL':
      return {
        title: t('error-OPEN_SEA_INTERNAL-title'),
        body: [t('error-OPEN_SEA_INTERNAL-body')],
      };
    case 'NOT_FOUND':
      return {
        title: t('error-NOT_FOUND-title'),
        body: [t('error-NOT_FOUND-body')],
      };
    case 'UNAUTHORIZED':
      return {
        title: t('error-UNAUTHORIZED-title'),
        body: [t('error-UNAUTHORIZED-body')],
      };
    case 'NETWORK':
      return {
        title: t('error-NETWORK-title'),
        body: [t('error-NETWORK-body')],
      };
    default:
      return {
        title: t('error-INTERNAL-title'),
        body: [t('error-INTERNAL-body')],
      };
  }
};
