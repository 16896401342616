import type { FieldPolicy, Reference } from '@apollo/client';
import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { mergeDeep } from '@apollo/client/utilities';
import type {
  RelayFieldPolicy,
  TExistingRelay,
  TRelayEdge,
  TRelayPageInfo,
} from '@apollo/client/utilities/policies/pagination';
import { __rest } from 'tslib';

type KeyArgs = FieldPolicy<unknown>['keyArgs'];

/**
 * Apollo Clientの`relayStylePagination`をカスタムした関数
 *
 * 重複した`ulid`の投稿がある場合は、その投稿を削除するようにしたもの。
 * アーティストチャンネルのページネーションでのみの使用を想定。
 * 主にNOTEコメントのある部分が独自に変更した箇所。
 * その他の差分は本体と比較してご確認ください。
 *
 * @see https://github.com/apollographql/apollo-client/blob/73a8af67d676f7855c2766ceff9f1b0ccb1c3db1/src/utilities/policies/pagination.ts#L94-L286
 */
export function relayStylePaginationForChannelContents<TNode extends Reference = Reference>(
  keyArgs: KeyArgs = false,
): RelayFieldPolicy<TNode> {
  return {
    keyArgs,

    read(existing, { canRead, readField }) {
      if (!existing) return existing;

      const edges: TRelayEdge<TNode>[] = [];

      existing.edges.forEach((edge) => {
        if (canRead(readField('node', edge))) {
          edges.push(edge);
        }
      });

      return {
        ...getExtras(existing),
        edges,
        pageInfo: {
          ...existing.pageInfo,
          startCursor: edges[0]?.cursor,
          endCursor: edges[edges.length - 1]?.cursor,
        },
      };
    },

    merge(existing, incoming, { args, isReference, readField }) {
      if (!existing) {
        existing = makeEmptyData();
      }

      if (!incoming) {
        return existing;
      }

      const incomingEdges = incoming.edges
        ? incoming.edges.map((edge) => {
            if (isReference((edge = { ...edge }))) {
              edge.cursor = readField<string>('cursor', edge);
            }
            return edge;
          })
        : [];

      if (incoming.pageInfo) {
        const { pageInfo } = incoming;
        const { startCursor, endCursor } = pageInfo;
        const firstEdge = incomingEdges[0];
        const lastEdge = incomingEdges[incomingEdges.length - 1];

        if (firstEdge && startCursor) {
          firstEdge.cursor = startCursor;
        }
        if (lastEdge && endCursor) {
          lastEdge.cursor = endCursor;
        }

        const firstCursor = firstEdge && firstEdge.cursor;
        if (firstCursor && !startCursor) {
          incoming = mergeDeep(incoming, {
            pageInfo: {
              startCursor: firstCursor,
            },
          });
        }
        const lastCursor = lastEdge && lastEdge.cursor;
        if (lastCursor && !endCursor) {
          incoming = mergeDeep(incoming, {
            pageInfo: {
              endCursor: lastCursor,
            },
          });
        }
      }

      let prefix = existing.edges;
      let suffix: typeof prefix = [];

      if (args && args.after) {
        // NOTE: 重複した`ulid`の投稿がある場合は、追加フェッチ側の投稿に置き換える
        prefix = updateExistingEdgesWithIncomingDuplicates({
          existingEdges: prefix,
          incomingEdges,
          readField,
        });
      } else if (args && args.before) {
        const index = prefix.findIndex((edge) => edge.cursor === args.before);
        suffix = index < 0 ? prefix : prefix.slice(index);
        prefix = [];
      } else if (incoming.edges) {
        /**
         * NOTE: 重複した`ulid`の投稿がある場合は、追加フェッチ側の投稿に置き換える
         * `before`がある時(上方向の追加フェッチ時)はこの処理を呼び出してはいけないので、この位置に実装する必要がある。
         */
        prefix = updateExistingEdgesWithIncomingDuplicates({
          existingEdges: prefix,
          incomingEdges,
          readField,
        });
      }

      // NOTE: 重複した`ulid`の投稿がある場合は、追加フェッチ側の投稿を削除する
      const incomingEdgesWithoutDuplicatedUlidPosts = removeDuplicatedUlidPostsFromIncoming({
        existingEdges: prefix,
        incomingEdges,
        readField,
      });
      const edges = [...prefix, ...incomingEdgesWithoutDuplicatedUlidPosts, ...suffix];

      const pageInfo: TRelayPageInfo = {
        ...incoming.pageInfo,
        ...existing.pageInfo,
      };

      if (incoming.pageInfo) {
        const { hasPreviousPage, hasNextPage, startCursor, endCursor, ...extras } =
          incoming.pageInfo;
        Object.assign(pageInfo, extras);
        if (!prefix.length) {
          if (void 0 !== hasPreviousPage) pageInfo.hasPreviousPage = hasPreviousPage;
          if (void 0 !== startCursor) pageInfo.startCursor = startCursor;
        }
        if (!suffix.length) {
          if (void 0 !== hasNextPage) pageInfo.hasNextPage = hasNextPage;
          if (void 0 !== endCursor) pageInfo.endCursor = endCursor;
        }
      }

      return {
        ...getExtras(existing),
        ...getExtras(incoming),
        edges,
        pageInfo,
      };
    },
  };
}

const getExtras = (obj: Record<string, unknown>) => __rest(obj, notExtras);
const notExtras = ['edges', 'pageInfo'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function makeEmptyData(): TExistingRelay<any> {
  return {
    edges: [],
    pageInfo: {
      hasPreviousPage: false,
      hasNextPage: true,
      startCursor: '',
      endCursor: '',
    },
  };
}

/**
 * 重複した`ulid`の投稿がある場合は、その投稿を追加フェッチ側の`edges`から削除する
 * アーティストチャンネルのページネーションでのみの使用を想定。
 */
const removeDuplicatedUlidPostsFromIncoming = <TNode extends Reference = Reference>({
  existingEdges,
  incomingEdges,
  readField,
}: {
  existingEdges: TRelayEdge<TNode>[];
  incomingEdges: TRelayEdge<TNode>[];
  readField: ReadFieldFunction;
}) => {
  const existingUlids = new Set(
    existingEdges.map((edge) => readField('ulid', readField('node', edge))),
  );
  return incomingEdges.filter(
    (edge) => !existingUlids.has(readField('ulid', readField('node', edge))),
  );
};

/**
 * 既存の`edge`と受信した`edge`を比較し、 `ulid` が重複している投稿を既存の`edge`に統合する関数。
 * 投稿後に直接キャッシュに追加したデータには正しい`cursor`が指定されていないため、そのデータを更新するために使用する。
 */
const updateExistingEdgesWithIncomingDuplicates = <TNode extends Reference = Reference>({
  existingEdges,
  incomingEdges,
  readField,
}: {
  existingEdges: TRelayEdge<TNode>[];
  incomingEdges: TRelayEdge<TNode>[];
  readField: ReadFieldFunction;
}) => {
  return existingEdges.map((edge) => {
    const existingUlid = readField('ulid', readField('node', edge));
    const duplicatedPost = incomingEdges.find(
      (incomingEdge) => readField('ulid', readField('node', incomingEdge)) === existingUlid,
    );
    return duplicatedPost ?? edge;
  });
};
