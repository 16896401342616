'use client';

import { useTranslations } from 'next-intl';
import type { ReactNode } from 'react';
import { Link } from 'react-transition-progress/next';
import { Divider } from './common/divider';
import { AuthLinksForGuest } from '@/components/layout/pc/left-navigation/auth-links-for-guest';
import { AuthenticatedNavigation } from '@/components/layout/pc/left-navigation/authenticated-navigation';
import { CopyRight } from '@/components/layout/pc/left-navigation/common/copy-right';
import { Support } from '@/components/layout/pc/left-navigation/common/support';
import { IconX } from '@/components/ui/icons';
import { IconLogo } from '@/components/ui/icons/IconLogo';
import { PATHS } from '@/constants/path';
import { usePathname } from '@/i18n/routing';
import { useAuthenticationContext } from '@/providers/auth-provider';

export const LeftNavigation = () => {
  const { isRegistered } = useAuthenticationContext();
  const t = useTranslations();

  const pathname = usePathname();
  return (
    <div className='sticky top-0 hidden h-screen w-[20rem] flex-col justify-between bg-kl-dark px-4 py-6 lg:flex'>
      <div>
        <Link href={PATHS.ROOT} className='block pb-5 pl-1'>
          <IconLogo color='white' width={120} height={22} />
        </Link>
        <Divider />
        {isRegistered ? <AuthenticatedNavigation currentPath={pathname} /> : <AuthLinksForGuest />}
        <Divider />
        <Support />
        <div className='mb-[5rem] border-0 border-t border-solid border-[#D6D6D6] pt-5 [&>a]:font-bold'>
          <LeftNaviMenuRow
            href={PATHS.X}
            externalLink
            icon={<IconX width={16} height={16} fillColor='white' />}
          >
            {t('settings-menu-x')}
          </LeftNaviMenuRow>
        </div>
        <CopyRight />
      </div>
    </div>
  );
};

const LeftNaviMenuRow = ({
  children,
  href,
  externalLink = false,
  icon,
}: {
  children: React.ReactNode;
  href: string;
  externalLink?: boolean;
  icon?: ReactNode;
}) => {
  return (
    <Link
      href={href}
      target={externalLink ? '_blank' : '_self'}
      className='flex items-center text-[1rem] text-white'
    >
      {icon && <span className='mr-[1rem]'>{icon}</span>}
      {children}
    </Link>
  );
};
