import { useTranslations } from 'next-intl';
import { NavItem } from '@/components/layout/pc/left-navigation/authenticated-navigation/nav-item';
import { Divider } from '@/components/layout/pc/left-navigation/common/divider';
import { PATHS } from '@/constants/path';
import { useAuthenticatedNavigation } from '@/hooks/use-authenticated-navigation';
import type { NavigationItem } from '@/hooks/use-authenticated-navigation';

type Props = {
  currentPath: string;
};

export const AuthenticatedNavigation = ({ currentPath }: Props) => {
  const t = useTranslations();
  const TICKET_NAV_ITEMS = [
    {
      title: `${t('settings-menu-my_tickets')}`,
      path: PATHS.TICKETS,
    },
  ];

  const {
    pcNavigationItems: BASE_NAV_ITEMS,
    unread: unreadNotification,
    avatar,
  } = useAuthenticatedNavigation();

  const NAV_ITEMS: NavigationItem[] = [...BASE_NAV_ITEMS, ...TICKET_NAV_ITEMS];
  const shouldActive = (item: NavigationItem) => {
    const isCurrentPathInNavItems =
      // TODO:型修正
      currentPath === item.path || (item.additionalPaths ?? []).includes(currentPath);
    const isCurrentPathMatchingNavItem = NAV_ITEMS.some((item) => currentPath === item.path);

    return isCurrentPathInNavItems || !isCurrentPathMatchingNavItem;
  };

  return (
    <div className='flex flex-col  text-white'>
      <div className='py-4'>
        {BASE_NAV_ITEMS.map((item) => (
          <NavItem
            key={item.title}
            item={item}
            unreadNotification={unreadNotification}
            isActive={shouldActive(item)}
            avatarImage={avatar}
          />
        ))}
      </div>
      <Divider />
      <div className='py-4'>
        {TICKET_NAV_ITEMS.map((item) => (
          <NavItem
            key={item.title}
            item={item}
            unreadNotification={unreadNotification}
            isActive={shouldActive(item)}
            isFontSizeSmall={true}
            avatarImage={avatar}
          />
        ))}
      </div>
    </div>
  );
};
