'use client';

import { useMutation, useQuery } from '@apollo/client';
import { useLocale } from 'next-intl';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { GetMeLanguageDocument, SetMyLanguageDocument } from '@/__generated__/graphql';
import { Language } from '@/__generated__/graphql';
import { ModalPortal } from '@/components/layout/modals/ModalPortal';
import { Button } from '@/components/ui/button/Button';
import { FIXED_VALUE_COOKIE, READONLY_COOKIE, ONE_DAY_IN_SECONDS } from '@/constants/cookie';
import { usePathname, useRouter } from '@/i18n/routing';
import { useAuthenticationContext } from '@/providers/auth-provider';
import { getCookie, setCookie } from '@/utils/cookie';

export const LanguageModal = () => {
  const t = useTranslations();

  const { isRegistered } = useAuthenticationContext();
  const [isOpen, setIsOpen] = useState(false);
  const { replace } = useRouter();
  const pathname = usePathname();
  const currentLocale = useLocale();
  const [setMyLanguage] = useMutation(SetMyLanguageDocument, {
    refetchQueries: [GetMeLanguageDocument],
  });
  const { data } = useQuery(GetMeLanguageDocument, {
    skip: !isRegistered,
  });
  const userLanguage: Language | null | undefined = data?.me?.language;

  const updateLanguage = async (language: Language) => {
    setCookie(FIXED_VALUE_COOKIE.KLEW_USER_LANGUAGE_SETTING_SKIP.KEY, {
      maxAge: ONE_DAY_IN_SECONDS,
      path: '/',
    });

    try {
      await setMyLanguage({ variables: { input: { language } } });
    } catch (e) {
      //NOTE: エラーが発生した場合はUI上でハンドリングせずコンソールにエラーを表示する
      console.error('Failed to update language');
    }

    setIsOpen(false);
  };

  const onClickReopenWithPrevLanguage = () => {
    const changeLocalValue = currentLocale === 'ja' ? 'en' : 'ja';
    //NOTE: URLのLocaleを変更する
    replace(pathname, { locale: changeLocalValue });
  };

  const onClose = () => {
    //NOTE: 背景のクリックで閉じないように空の関数を設定
  };

  const currentLanguage = currentLocale === 'ja' ? Language.Ja : Language.En;

  const isSettingsPage = /^\/(en\/)?settings(\/|$)/.test(pathname);

  useEffect(() => {
    if (isSettingsPage) return;
    const cookieLanguage = getCookie(READONLY_COOKIE.KLEW_USER_LANGUAGE_SETTING_SKIP.KEY);

    //NOTE: cookieに保存された言語設定がある場合、処理をスキップ
    if (cookieLanguage) {
      return;
    }

    //NOTE: cookieに保存された言語設定がない場合、DBの設定を用いて言語を比較する
    if (userLanguage) {
      const currentLanguage = currentLocale.toLowerCase() === 'ja' ? Language.Ja : Language.En;
      setIsOpen(userLanguage !== currentLanguage);
      return;
    }
  }, [userLanguage, currentLocale]);

  return (
    <ModalPortal
      {...{
        portalId: 'blockerModalPortal',
        isOpen,
        onClose,
        body: t('switch_languages-description'),
      }}
    >
      <div className='space-y-2.5x pt-4x text-center'>
        <Button
          label={t('switch_languages-button_continue')}
          fullWidth
          onClick={() => updateLanguage(currentLanguage)}
        />
        <span
          className='mx-auto inline-block cursor-pointer text-xs underline'
          onClick={onClickReopenWithPrevLanguage}
        >
          {t('switch_languages-button_change')}
        </span>
      </div>
    </ModalPortal>
  );
};
