'use client';

import { IconBell } from '@/components/ui/icons/IconBell';

type Props = {
  width: string;
  height: string;
  className: string;
  unreadNotification: boolean;
};

export const Notification = ({ width, height, className, unreadNotification }: Props) => {
  return (
    <div className='relative'>
      {unreadNotification && (
        <span className='absolute right-0 top-0 z-10 block h-2 w-2 rounded-full bg-[#f00]' />
      )}
      <IconBell width={width} height={height} className={className} />
    </div>
  );
};
