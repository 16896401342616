import type { useTranslations } from 'next-intl';
import { ErrorValidationFailedReason, type ErrorValidationFailed } from '@/__generated__/graphql';
import type { ModalContentType } from '@/components/layout/modals/types';
import { PAYLOAD_ERROR_MAP } from '@/lib/apollo/payload-error-map';

export const ngWordErrorDialog = (
  result: ErrorValidationFailed,
  showDialog: (message: ModalContentType) => void,
  t: ReturnType<typeof useTranslations>,
) => {
  const isNgWord = result.details?.some(
    (detail) =>
      // @ts-expect-error `ErrorValidationFailed` の `reason` を `validationReason` に変更して使用しているため。追って修正する。
      detail.validationReason === ErrorValidationFailedReason.NgWord,
  );
  if (isNgWord) {
    showDialog(PAYLOAD_ERROR_MAP({ __typename: 'ErrorValidationNgWord' }, t));
  } else {
    showDialog(PAYLOAD_ERROR_MAP(result, t));
  }
};
