import { ModalBase } from './ModalBase';
import type { ModalBaseType } from './types';
import { DynamicPortal } from '@/utils/dynamic-portal';

export const ModalPortal = (props: ModalBaseType) => {
  const { portalId } = props;
  return (
    <DynamicPortal id={portalId || 'modalPortal'}>
      <ModalBase {...props} />
    </DynamicPortal>
  );
};
