import clsx from 'clsx';
import { Link } from 'react-transition-progress/next';
import { Notification } from '@/components/layout/pc/left-navigation/authenticated-navigation/notification';
import { Avatar } from '@/components/ui/avatar/Avatar';
import { IconEye } from '@/components/ui/icons/IconEye';
import { IconHearts2 } from '@/components/ui/icons/IconHearts2';
import { IconList } from '@/components/ui/icons/IconList';
import { IconLottery } from '@/components/ui/icons/IconLottery';
import { IconTickets } from '@/components/ui/icons/IconTickets';
import { PATHS } from '@/constants/path';
import type { NavigationItem } from '@/hooks/use-authenticated-navigation';

type Props = {
  item: NavigationItem;
  isActive: boolean;
  unreadNotification: boolean;
  isFontSizeSmall?: boolean;
  avatarImage: string;
};

export const NavItem = ({
  item,
  isActive,
  unreadNotification,
  isFontSizeSmall = false,
  avatarImage,
}: Props) => {
  const activeStyle = isActive ? '' : 'opacity-50';

  return (
    <Link
      href={item.path}
      className='ml-1 flex content-center items-center py-2 text-center text-sm first:pt-0 last:pb-0'
    >
      <NavIcon {...{ item, activeStyle, unreadNotification, avatarImage }} />
      <div
        className={clsx(
          'pl-2 font-bold',
          isActive ? '' : 'opacity-50',
          isFontSizeSmall ? 'text-xs' : 'text-sm',
        )}
      >
        {item.title}
      </div>
    </Link>
  );
};

type NavIconProps = {
  item: NavigationItem;
  activeStyle: string;
  unreadNotification: boolean;
  avatarImage: string;
};

const NavIcon: React.FC<NavIconProps> = ({
  item,
  activeStyle,
  unreadNotification,
  avatarImage,
}) => {
  const commonProps = { width: '20', height: '20', className: activeStyle };
  switch (item.path) {
    case PATHS.CHANNELS:
      return <IconList {...commonProps} />;
    case PATHS.FOLLOWING:
      return <IconHearts2 {...commonProps} />;
    case PATHS.ROOT:
      return <IconEye {...commonProps} />;
    case PATHS.NOTIFICATIONS_ME || PATHS.NOTIFICATIONS_NEWS || PATHS.NOTIFICATIONS_NEWS_DETAIL:
      return <Notification {...commonProps} unreadNotification={unreadNotification} />;
    case PATHS.SETTINGS:
      // TODO: 正しい画像にする
      return <Avatar size='xs' src={avatarImage} />;
    case PATHS.TICKETS_LOTTERY:
      return <IconTickets {...commonProps} />;
    case PATHS.TICKETS_USED:
      return <IconLottery {...commonProps} />;
    default:
      return null;
  }
};
