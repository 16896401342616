'use client';

import { createContext, useContext, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { SnackBar } from '@/components/ui/snack-bar';
import type { SnackbarContentType } from '@/components/ui/snack-bar/types';

interface SnackbarContextType {
  showSnackbar: (content: SnackbarContentType) => void;
  closeSnackbar: () => void;
}

export const SnackbarContext = createContext<SnackbarContextType>({
  showSnackbar: () => {},
  closeSnackbar: () => {},
});

export default function useSnackbar() {
  return useContext(SnackbarContext);
}

export const SnackbarProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [content, setContent] = useState<SnackbarContentType>({
    message: '',
    bottom: 0,
  });
  const [isOpen, setIsOpen] = useState(false);

  const showSnackbar = (content: SnackbarContentType) => {
    setContent({
      message: content.message,
      bottom: content.bottom || 0,
    });
    setIsOpen(true);
  };

  const closeSnackbar = () => {
    setIsOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar, closeSnackbar }}>
      {children}
      <div className='pointer-events-none fixed left-0 top-0 size-full justify-center md:flex'>
        <div className='h-full md:flex md:w-md-base lg:w-lg-base'>
          <div className='h-full shrink-0 lg:w-[20rem]' />
          <div className='relative w-full'>
            <div className='absolute size-full px-[2rem]'>
              <div className='pointer-events-auto'>
                <SnackBar
                  isVisible={isOpen}
                  message={content.message}
                  bottom={content.bottom}
                  onHideSnackBar={closeSnackbar}
                  isPortal
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SnackbarContext.Provider>
  );
};
