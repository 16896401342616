import clsx from 'clsx';
import type { JSX } from 'react';
import { useEffect, forwardRef, useState } from 'react';
import { ANIMATION_TIME } from '@/constants/animation';

const TRANSITION_TIME = 500;

type SnackBarProps = {
  message: string;
  isVisible: boolean;
  onHideSnackBar: () => void;
  bottom?: number;
  isPortal?: boolean;
} & JSX.IntrinsicElements['div'];

export const SnackBar = forwardRef<HTMLDivElement, SnackBarProps>(
  ({ message, isVisible, onHideSnackBar, isPortal = false, bottom = 20, ...divProps }, ref) => {
    const [shouldMount, setShouldMount] = useState(false);
    const [isFadeOut, setIsFadeOut] = useState(false);
    const canDisplay = isVisible || shouldMount;

    useEffect(() => {
      const timer = setTimeout(() => {
        setIsFadeOut(true);
        setTimeout(() => {
          onHideSnackBar();
          setIsFadeOut(false);
          setShouldMount(false);
        }, TRANSITION_TIME);
      }, ANIMATION_TIME);
      return () => {
        clearTimeout(timer);
      };
    }, [onHideSnackBar]);

    if (!canDisplay) return null;

    return (
      <div
        ref={ref}
        className={clsx(
          'duration-250  z-50 flex h-10 w-full max-w-sm  items-center justify-center rounded-lg bg-gr-opacity-10 p-4 transition-opacity ease-in-out',
          isFadeOut ? 'opacity-0' : 'opacity-100',
          isPortal ? 'absolute' : 'fixed left-1/2 -translate-x-1/2 transform',
        )}
        style={{
          bottom: `${bottom}px`,
          width: 'calc(100% - 4rem)',
          transition: `opacity ${TRANSITION_TIME}ms ease-in-out`,
        }}
        {...divProps}
      >
        <p className='text-xs text-black'>{message}</p>
      </div>
    );
  },
);

SnackBar.displayName = 'SnackBar';
