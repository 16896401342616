import { useTranslations } from 'next-intl';
import { ModalPortal } from '@/components/layout/modals/ModalPortal';
import { Button } from '@/components/ui/button/Button';
import { IconLogo } from '@/components/ui/icons';
import { PATHS } from '@/constants/path';
import { usePathname, useRouter } from '@/i18n/routing';

export const WelcomeModal = ({ isOpen }: { isOpen: boolean }) => {
  const t = useTranslations();
  const pathName = usePathname();
  const { push } = useRouter();
  const onStart = () => {
    push(pathName);
  };

  const onClose = () => {
    //NOTE: 背景のクリックで閉じないように空の関数を設定
  };

  return (
    <ModalPortal
      {...{
        isOpen,
        onClose,
        title: (
          <span className='inline-flex items-center tracking-widest'>
            {t.rich('sign_up-complete-welcome', {
              klew_logo: () => (
                <IconLogo
                  width={100}
                  height={20}
                  className='flex-0 mx-[0.5rem] h-[2rem] w-[10rem]'
                  color='black'
                />
              ),
            })}
          </span>
        ),
        body: t('sign_up-complete-description'),
        children: (
          <div className='mb-3.75x mt-2.5x rounded-lg bg-[#f5f5f5] p-4 text-body-12 tracking-widest [&>a]:underline'>
            {t.rich('sign_up-complete-caution', {
              br: () => <br />,
              link: (value) => (
                <a href={PATHS.HELP} target='_blank'>
                  {value}
                </a>
              ),
            })}
          </div>
        ),
        PrimaryButtonComponent: () => (
          <Button
            label={t('sign_up-complete-start')}
            color='gradient'
            fullWidth
            onClick={onStart}
          />
        ),
        secondaryButton: t('sign_up-complete-know_more'),
        redirectUrl: PATHS.ABOUT,
      }}
    />
  );
};
