'use client';

import { useSearchParams } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import { WelcomeModal } from './welcome-modal';
import { QUERIES } from '@/constants/path';

export const QueryParamsModal = () => {
  const searchParams = useSearchParams();
  const [isWelcomeOpen, setIsWelcomeOpen] = useState(
    searchParams.get('modal') === QUERIES.WELCOME_MODAL.value,
  );

  useEffect(() => {
    setIsWelcomeOpen(searchParams.get('modal') === QUERIES.WELCOME_MODAL.value);
  }, [searchParams]);

  return (
    <>
      <WelcomeModal isOpen={isWelcomeOpen} />
    </>
  );
};
